// import external dependencies
import 'jquery';

// Import Slick
import 'slick-carousel/slick/slick.min';

// Import Fancybox
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';

// import then needed Font Awesome functionality
import { config, library, dom } from '@fortawesome/fontawesome-svg-core';

config.searchPseudoElements=true;
//config.showMissingIcons = false;

//import { faAngleDown } from '@fortawesome/free-regular-svg-icons';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

// add the imported icons to the library
library.add(faAngleDown);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();


// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
