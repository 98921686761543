export default {
  init() {
    // JavaScript to be fired on all pages

     // sliders
     $('.header_slider').each(function(){
      $( this ).slick({
        lazyLoad: 'progressive',
        autoplay: true,
        pauseOnHover: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        speed: 3500,
        fade: true,
        arrows: true
      });
    });

    // Initialize Fancybox
    $('[data-fancybox], .gallery-item a').fancybox({
      // Fancybox options
    });

    $('.gallery-item a').attr('data-fancybox', 'gallery');
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // hamburger menu
    // menu toggle mobile 
    $('.icon_burger').on( 'click', function() {
      $('.top-menu').toggleClass('top-animate');
      $('.mid-menu').toggleClass('mid-animate');
      $('.bottom-menu').toggleClass('bottom-animate');

      $('.nav-primary').toggleClass('active-menu');
      
      $('body').toggleClass('noscroll');
    });

    $('.menu-item-has-children .svg-inline--fa').on( 'click', function() {
      
      $( this ).parent().find( '.sub-menu' ).toggleClass( 'active-sub-menu' );

    });
    
  },
};
